<template>
  <section class="marketplace-product-detail" :class="{ 'marketplace-product-detail--preview': !show_detail }">
    <div v-if="(loading && !show_detail && !product_data.id) || (!loading && product_data.id && !show_detail )" class="marketplace-product-detail__preview">
      <div v-if="loading" class="element-loading marketplace-product-detail__preview-image"/>
      <router-link v-else :to="`/products/${product_data.categories.join('/')}/${product_data.id}`">
        <img :src="get_correct_image" alt="Product image" class="marketplace-product-detail__preview-image">
      </router-link>
      <router-link :to="loading ? '' : `/products/${product_data.categories.join('/')}/${product_data.id}`">
        <div class="marketplace-product-detail__preview-section marketplace-product-detail__preview-info">
          <h3 class="marketplace-product-detail__preview-title" :class="{ 'element-loading': loading }">{{ title }}</h3>
          <p class="marketplace-product-detail__preview-message" v-html="message" :class="{ 'element-loading': loading }"/>
        </div>
      </router-link>
      <div class="marketplace-product-detail__preview-section marketplace-product-detail__preview-checkout">
        <p
          v-if="calculate_percentage(get_correct_price_object.compare_at_price, get_correct_price_object.price) > 0"
          class="marketplace-product-detail__preview-price marketplace-product-detail__preview-title"
          :class="{ 'element-loading': loading }"
        >
          <span>
            <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            {{ get_correct_price_object.price }}
            <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          </span>
          <span>
            <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
            {{ get_correct_price_object.compare_at_price }}
            <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          </span>
          <span>| {{ calculate_percentage(get_correct_price_object.compare_at_price, get_correct_price_object.price) }}%</span>
        </p>
        <p
          v-else
          class="marketplace-product-detail__preview-title"
          itemprop="price"
          :class="{ 'element-loading': loading }"
        >
          <span v-if="is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
          {{ get_correct_price_object.price }}
          <span v-if="!is_currency_symbol(currency_symbol)">{{ currency_symbol }}</span>
        </p>
        <p
          v-if="number_of_sold"
          class="marketplace-product-detail__preview-data--larger marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ number_of_sold }} {{ $translate("products.number_of_sold") }}</span><package_sold_icon />
        </p>
        <p
          v-if="product_origin"
          class="marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ $translate("products.origin") }} {{ product_origin }}</span><location_icon />
        </p>
        <p
          v-if="product_data.project_domain"
          class="marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ $translate("products.store_link") }}</span>
          <a class="marketplace-link" :href="`http://${product_data.project_domain}`" target="_blank" rel="noopener noreferrer">{{ product_data.project_domain }}</a>
        </p>
        <Checkout
          v-if="!loading"
          :product_data="product_data"
          :first_variant_button="true"
          :add_description="false"
          :color_dark="false"
          :show_title="false"
        />
      </div>
    </div>

    <div v-else-if="loading || product_data.id" class="marketplace-product-detail__detail">
      <Product
        :product_data="product_data"
        :expand="true"
        :loading="loading"
        :selected_marketplace_language="language"
      />
      <div class="marketplace-product-detail__detail-info">
        <p
          v-if="number_of_sold"
          class="marketplace-product-detail__preview-data--larger marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ number_of_sold }} {{ $translate("products.number_of_sold") }}</span><package_sold_icon />
        </p>
        <p
          v-if="product_origin"
          class="marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ $translate("products.origin") }} {{ product_origin }}</span><location_icon />
        </p>
        <p
          v-if="product_data.project_domain"
          class="marketplace-product-detail__preview-data"
          :class="{ 'element-loading': loading }"
        >
          <span>{{ $translate("products.store_link") }} </span>
          <a class="marketplace-link" :href="`http://${product_data.project_domain}`" target="_blank" rel="noopener noreferrer">{{ product_data.project_domain }}</a>
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
import countries from "../../../../data/countries"
import calculate_percentage from "../../../Client/methods/calculate_percentage"
import { currency_signs } from "../../../Shared/constants/other"
import get_correct_first_image from "../../../Shared/methods/get_correct_first_image"
import get_correct_price_object from "../../../Shared/methods/get_correct_price_object"
import location_icon from "../../../Shared/components/icon_components/location-icon"
import package_sold_icon from "../../../Shared/components/icon_components/package-sold-icon"
import get_message_or_types_from_product_description from "../../../Shared/methods/get_message_or_types_from_product_description"
import { MARKETPLACE } from "../../constants/other"
import is_currency_symbol from "../../../Shared/methods/is_currency_symbol"
import { default_language } from '../../../../../functions/constans/other'
import get_correct_translation_value from '../../../Shared/methods/get_correct_translation_value'

const Checkout = () => import("../../../Shared/components/utils/checkout")
const Product = () => import("../../../Client/components/base/Product")

export default {
  components: {
    Product,
    Checkout,
    location_icon,
    package_sold_icon
  },
  props: {
    product_data: {
      type: Object,
      required: true
    },
    show_detail: {
      type: Boolean,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    selected_language: {
      type: String,
      required: false,
      default: ""
    }
  },
  data() {
    return {
      chosen_options: [],
      countries
    }
  },
  computed: {
    ...mapState(MARKETPLACE, [
      "marketplace_currency",
    ]),
    language() {
      return this.selected_language || Object.keys((this.product_data.variants || [{}])[0].translations || {})[0] || default_language
    },
    currency_symbol() {
      return currency_signs[
        get_correct_translation_value(this.product_data, 'currency_code', [this.language]) ||
        this.marketplace_currency
      ]
    },
    title() {
      return get_correct_translation_value(this.product_data, 'title', [this.language])
    },
    body_html() {
      return get_correct_translation_value(this.product_data, 'body_html', [this.language])
    },
    get_correct_image() {
      return this.loading ? " " : get_correct_first_image(this.product_data)
    },
    get_correct_price_object() {
      return this.loading ? {} : get_correct_price_object(this.chosen_options, this.product_data.variants, this.language)
    },
    message() {
      return this.loading ? " " : get_message_or_types_from_product_description(this.body_html, true)
    },
    number_of_sold() {
      return this.loading ? " " : (this.product_data.variants || []).reduce((total, { sold = 0 }) => total + sold, 0)
    },
    product_origin() {
      return this.loading ? " " : this.product_data.project_country ? countries.find(({ code }) => this.product_data.project_country === code).name : ""
    },
  },
  methods: {
    calculate_percentage,
    is_currency_symbol
  }
}
</script>

<style lang="scss" scoped>
  @use "../../../../styles/_global-constants" as *;
  @use "../../../../styles/_marketplace-constants" as *;
  @use "../../../../styles/element_loading" as *;

  .marketplace-product-detail {

    &__detail-info {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      column-gap: $spacing--small;
      border-top: 1px solid $grey--light;
      padding: $spacing--medium $spacing--small 0;
      margin: $spacing--medium 0 $spacing--medium;
      text-align: center;

      @media (max-width: $tablet) {
        grid-template-columns: 1fr 1fr;
        row-gap: $spacing--medium;
      }

      @media (max-width: $mobile) {
        grid-template-columns: 1fr;
        row-gap: $spacing--small;
      }

      p {
        font-size: $font-size--medium;

        svg {
          width: $small-icon !important;
          height: $small-icon !important;
        }
      }
    }

    &--preview {
      padding: $spacing--large 0;
      border-bottom: 1px solid $grey--light;

      @media (max-width: $maximum-width) {
        padding: $spacing--medium 0;
      }

      &:last-child {
        border: none;
      }
    }

    &__preview {
      $image-size: 200px;

      display: grid;
      padding: 0 $spacing--small;
      grid-template-columns: $image-size calc(70% - #{$image-size} / 2) calc(30% - #{$image-size} / 2);
      grid-template-areas: "preview-image preview-description preview-checkout";

      &-image {
        width: $image-size;
        height: $image-size;
        object-fit: contain;
        margin: auto;
        grid-area: preview-image;
      }

      &-section {
        position: relative;
        padding: 0 $spacing--small;

        @media (max-width: $tablet) {
          padding: 0 0 0 $spacing--small;
        }

        .checkout__wrapper {
          position: absolute !important;
          bottom: 0;
          right: 0;
          width: 200px;
          min-width: auto;
          text-align: right;
        }
      }

      @media (max-width: $maximum-width) {
        $image-size: 150px;
        grid-template-columns: $image-size calc(65% - #{$image-size} / 2) calc(35% - #{$image-size} / 2);

        &-image {
          width: $image-size;
          height: $image-size;
          max-height: 200px;
        }
      }

      @media (max-width: $tablet) {
        $image-size: 200px;
        grid-template-columns: $image-size calc(100% - #{$image-size});
        grid-template-areas:
          "preview-image preview-description"
          "preview-checkout preview-checkout";

        &-image {
          width: $image-size;
          height: $image-size;
        }

        .checkout__wrapper {
          top: 0;
          right: auto;
          bottom: auto;
          left: 0;
          text-align: left;
        }
      }

      @media (max-width: $tablet--small) {
        grid-template-columns: 40% 60%;

        &-image {
          width: 100%;
          height: 100%;
        }
      }

      &-data {
        margin: 0;
        font-size: $font-size--small;
        line-height: 18px;
        color: $grey;

        @media (max-width: $tablet) {
          margin: 5px 0 0;
        }

        &--larger svg {
          width: 16px !important;
          height: 16px !important;
        }

        span {
          display: inline-block;
          vertical-align: middle;
        }

        svg {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
          width: $tiny-icon;
          height: $tiny-icon;
          fill: $grey;
        }

        a {
          display: inline-block;
          vertical-align: middle;
          margin-left: 5px;
        }
      }

      &-checkout {
        text-align: right;
        grid-area: preview-checkout;
        padding: 0;

        @media (max-width: $tablet) {
          margin: $spacing--medium 0 0;
        }
      }

      &-title {
        font-size: $font-size--large;
        line-height: 26px;
        min-height: 26px;
        font-weight: bold;
        margin: 0 0 $spacing--small;

        @media (max-width: $tablet) {
          margin: 0 0 $spacing--small;
        }

        @media (max-width: $tablet--small) {
          font-size: 18px;
          line-height: normal;
        }
      }

      &-info {
        grid-area: preview-description;
      }

      &-message {
        height: 150px;
        margin: 0;
        overflow: auto;
        font-size: $font-size--small;
      }

      &-price {

        & > span {
          display: inline-block;
          vertical-align: middle;

          &:nth-child(2){
            font-size: 11px;
            color: var(--font_heavy);
            text-decoration: line-through;
            font-weight: 400;
          }

          &:last-child {
            font-size: 13px;
            font-weight: 400;
          }

          &:first-child {
            margin-right: 10px;
            color: $red-color;
          }
        }

        p {
          margin: 0;
        }
      }
    }
  }
</style>
