<template>
  <section class="marketplace-categories">
    <div class="marketplace-categories__categories">
      <h2 class="marketplace-categories__sub-title" :class="{ 'element-loading': loading_categories }">
        {{ parent_category ? parent_category.name : $translate("categories.all_categories") }}
      </h2>
      <div
        v-for="({ id, name, is_final }, index) in categories"
        :key="`category-list-${index}`"
        class="marketplace-categories__category"
        :class="{ 'element-loading': loading_categories }"
      >
        <router-link v-if="id" :to="create_route(id, is_final)">
          <p>{{ name }}</p>
        </router-link>
        <p v-else />
      </div>
    </div>
    <div class="marketplace-categories__tiles-wrapper">
      <h1 class="title title__normal basket__title marketplace-title marketplace-categories__title">
        {{ $translate("categories.title") }}
      </h1>
      <div class="marketplace-categories__tiles">
        <div
          v-for="({ id, name, src }, index) in top_categories"
          :key="`category-${index}`"
          class="marketplace-categories__tile"
          :class="{ 'element-loading': loading_categories }"
        >
          <router-link v-if="id" :to="`/products${$route.params.pathMatch ? `/${$route.params.pathMatch}` : ''}/${id}`">
            <img :src="src || '/static/backgrounds/default-img.jpg'" :alt="`Category ${name}`">
            <span>{{ name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapMutations } from "vuex";
import { get_marketplace_categories } from "../../constants/endpoints";
import { MARKETPLACE } from "../../constants/other";
import { UPDATE_CATEGORIES } from "../../stores/constants";
import Product_detail from "../utils/Product_detail"

export default {
  components: {
    Product_detail
  },
  data() {
    return {
      categories: [{},{},{},{},{},{},{},{},{},{},{},{},{},{},{}],
      top_categories: [{},{},{},{},{},{},{},{},{}],
      loading_categories: true,
      parent_category: null
    }
  },
  computed: {
    get_route_categories() {
      return this.$route.params.pathMatch ? this.$route.params.pathMatch.split("/") : []
    },
  },
  watch: {
    $route: {
      handler() {
        this.load_categories()
      },
      deep: true
    }
  },
  mounted() {
    this.load_categories()
  },
  methods: {
    ...mapMutations(MARKETPLACE, {
      update_categories: UPDATE_CATEGORIES
    }),
    async load_categories() {
      this.loading_categories = true

      const { data: { items, parent = null } } = await get_marketplace_categories(this.get_route_categories)

      this.categories = items
      this.top_categories = items.slice(0, 8)
      this.parent_category = parent
      this.loading_categories = false
    },
    create_route(id, is_final) {
      return is_final ? `/products/${this.$route.params.pathMatch}/${id}` :
        `/categories${this.get_route_categories.length ? '/' + this.get_route_categories.join('/') : ''}/${id}`
    }
  }
};
</script>

<style lang="scss" scoped>
@use "../../../../styles/_global-constants" as *;
@use "../../../../styles/_marketplace-constants" as *;
@use "../../../../styles/element_loading.scss";
@use "../../../../styles/rise_up_effect.scss";

.marketplace-categories {
  display: grid;
  grid-template-areas: "categories tiles tiles tiles";
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: $spacing--small;
  padding: $spacing--medium 0;
  grid-template-rows: auto;

  @media (max-width: $maximum-width) {
    padding: $spacing--medium $spacing--small;
  }

  @media (max-width: $tablet) {
    padding: $spacing--medium $spacing--small;
    grid-template-areas: "categories tiles tiles";
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: $tablet--small) {
    padding: 0 $spacing--small $spacing--small;
    grid-template-areas:
      "tiles tiles" "categories categories";
    grid-template-columns: 1fr 1fr;
  }

  &__title {
    margin: 0 0 $spacing--large;
  }

  &__sub-title {
    margin: 0 0 $spacing--small;

    @media (max-width: $tablet--small) {
      font-size: $font-size--large;
      margin: $spacing--medium 0;
      text-align: center;
    }
  }

  &__categories {
    grid-area: categories;
  }

  &__category {
    margin-bottom: $spacing--tiny;
    text-decoration: underline;

    p {
      min-height: 20px;
      line-height: 20px;
      margin: 0;
    }

    @media (max-width: $tablet--small) {
      display: inline-block;
      width: calc(50% - 1.5px);
      padding-left: $spacing--tiny;
      vertical-align: top;
      font-size: $font-size--medium;

      &:nth-child(even) {
        padding-right: $spacing--tiny;
        padding-left: 0;
      }
    }
  }

  &__tile {
    position: relative;
    text-align: center;
    font-size: $font-size--very-large;
    border-radius: $border-radius;
    transition: $default-transition;
    cursor: pointer;
    overflow: hidden;

    @media (max-width: $maximum-width) {
      font-size: $font-size--medium-large;
      font-weight: 500;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      filter: blur(3px);
      opacity: 0.7;
      z-index: -1;
      object-fit: cover;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }

    a {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:after {
      display: block;
      content: "";
      padding-bottom: 100%;
    }

    &:hover {
      @extend .rise-up-effect;
    }

    &s {
      &-wrapper {
        grid-area: tiles;
      }

      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: $spacing--small;
      row-gap: $spacing--small;
      grid-auto-rows: minmax(min-content, max-content);

      @media (max-width: $tablet) {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }
  }
}
</style>
